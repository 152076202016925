module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<dl class="foldpanel" id="my-foldpanel">\r\n    <dt>Hats\r\n        <div class="pic">\r\n            <img src="' +
((__t = ( require('./images/pronav+.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n            <img src="' +
((__t = ( require('./images/pronav-.png') )) == null ? '' : __t) +
'" alt="" class="img2">\r\n        </div>\r\n    </dt>\r\n    <dd>\r\n        <div class="navs">\r\n            <a href="javascript:;" class="active">\r\n                <span>Bucket Hat</span>\r\n                <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                <img src="' +
((__t = ( require('./images/pronav-1.png') )) == null ? '' : __t) +
'" alt="" class="img2">\r\n            </a>\r\n            <ul>\r\n                <li>\r\n                    <a href="product-details.html">\r\n                        <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                        <span>Bucket Hat</span>\r\n                    </a>\r\n                </li>\r\n                <li>\r\n                    <a href="javascript:;">\r\n                        <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                        <span>Bucket Hat</span>\r\n                    </a>\r\n                </li>\r\n            </ul>\r\n        </div>\r\n        <div class="navs">\r\n            <a href="javascript:;">\r\n                <span>Bucket Hat</span>\r\n                <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                <img src="' +
((__t = ( require('./images/pronav-1.png') )) == null ? '' : __t) +
'" alt="" class="img2">\r\n            </a>\r\n            <ul>\r\n                <li>\r\n                    <a href="product-details.html">\r\n                        <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                        <span>Bucket Hat</span>\r\n                    </a>\r\n                </li>\r\n                <li>\r\n                    <a href="javascript:;">\r\n                        <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                        <span>Bucket Hat</span>\r\n                    </a>\r\n                </li>\r\n            </ul>\r\n        </div>\r\n        <div class="navs">\r\n            <a href="javascript:;">\r\n                <span>Bucket Hat</span>\r\n                <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                <img src="' +
((__t = ( require('./images/pronav-1.png') )) == null ? '' : __t) +
'" alt="" class="img2">\r\n            </a>\r\n            <ul>\r\n                <li>\r\n                    <a href="product-details.html">\r\n                        <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                        <span>Bucket Hat</span>\r\n                    </a>\r\n                </li>\r\n                <li>\r\n                    <a href="javascript:;">\r\n                        <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                        <span>Bucket Hat</span>\r\n                    </a>\r\n                </li>\r\n            </ul>\r\n        </div>\r\n        <div class="navs">\r\n            <a href="javascript:;">\r\n                <span>Bucket Hat</span>\r\n                <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                <img src="' +
((__t = ( require('./images/pronav-1.png') )) == null ? '' : __t) +
'" alt="" class="img2">\r\n            </a>\r\n            <ul>\r\n                <li>\r\n                    <a href="product-details.html">\r\n                        <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                        <span>Bucket Hat</span>\r\n                    </a>\r\n                </li>\r\n                <li>\r\n                    <a href="javascript:;">\r\n                        <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                        <span>Bucket Hat</span>\r\n                    </a>\r\n                </li>\r\n            </ul>\r\n        </div>\r\n        <div class="navs">\r\n            <a href="javascript:;">\r\n                <span>Bucket Hat</span>\r\n                <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                <img src="' +
((__t = ( require('./images/pronav-1.png') )) == null ? '' : __t) +
'" alt="" class="img2">\r\n            </a>\r\n            <ul>\r\n                <li>\r\n                    <a href="product-details.html">\r\n                        <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                        <span>Bucket Hat</span>\r\n                    </a>\r\n                </li>\r\n                <li>\r\n                    <a href="javascript:;">\r\n                        <img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                        <span>Bucket Hat</span>\r\n                    </a>\r\n                </li>\r\n            </ul>\r\n        </div>\r\n    </dd>\r\n    <dt>Storm Jacket\r\n        <div class="pic">\r\n        <img src="' +
((__t = ( require('./images/pronav+.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n        <img src="' +
((__t = ( require('./images/pronav-.png') )) == null ? '' : __t) +
'" alt="" class="img2">\r\n    </div></dt>\r\n    <dd>\r\n        <a href="javascript:;"><span>Bucket Hat</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Sun Hat</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Windproof Cap</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Ultra Light Hat</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Waterproof Cap</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Peaked Cap</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Baseball Cap</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n    </dd>\r\n    \r\n    <dt>Ski Gloves\r\n        <div class="pic">\r\n            <img src="' +
((__t = ( require('./images/pronav+.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n            <img src="' +
((__t = ( require('./images/pronav-.png') )) == null ? '' : __t) +
'" alt="" class="img2">\r\n        </div>\r\n    </dt>\r\n    <dd>\r\n        <a href="javascript:;"><span>Bucket Hat</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Sun Hat</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Windproof Cap</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Ultra Light Hat</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Waterproof Cap</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Peaked Cap</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Baseball Cap</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n    </dd>\r\n    \r\n    <dt>Rain Boots\r\n        <div class="pic">\r\n            <img src="' +
((__t = ( require('./images/pronav+.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n            <img src="' +
((__t = ( require('./images/pronav-.png') )) == null ? '' : __t) +
'" alt="" class="img2">\r\n        </div>\r\n    </dt>\r\n    <dd>\r\n        <a href="javascript:;"><span>Bucket Hat</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Sun Hat</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Windproof Cap</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Ultra Light Hat</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Waterproof Cap</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Peaked Cap</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n        <a href="javascript:;"><span>Baseball Cap</span><img src="' +
((__t = ( require('./images/pronav-left.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n    </dd>';

}
return __p
}