module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="pro-model">\r\n    <div class="model-out">\r\n        <img src="' +
((__t = ( require('./images/model-out.png') )) == null ? '' : __t) +
'" alt="">\r\n    </div>\r\n    <div class="content">\r\n        <div class="show1">\r\n            <div class="top">\r\n                <ul>\r\n                </ul>\r\n            </div>\r\n            <form>\r\n                <ul>\r\n                    <li>\r\n                        <div class="name">\r\n                            <span>Name *</span>\r\n                            <input type="text">\r\n                        </div>\r\n                        <div class="tel">\r\n                            <span>Tel *</span>\r\n                            <input type="text">\r\n                       </div>\r\n                       <div class="email">\r\n                            <span>E-mail *</span>\r\n                            <input type="text">\r\n                       </div>\r\n                    </li>\r\n                    <li>\r\n                        <div class="message">\r\n                            <span>Message *</span>\r\n                            <input type="text">\r\n                       </div>\r\n                    </li>\r\n                </ul>\r\n                <input type="button" value="Submit">\r\n            </form>\r\n            <div class="list">\r\n                <p>List of Purchased Items:</p>\r\n                <ul>\r\n                    <li>\r\n                        <a href="javascript:;">\r\n                            <div class="picbox">\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                            </div>\r\n                            <div class="text">\r\n                                <div class="time">2023.08.08</div>\r\n                                <div class="piece">36 pieces in total</div>\r\n                            </div>\r\n                        </a>\r\n                    </li>\r\n                    <li>\r\n                        <a href="javascript:;">\r\n                            <div class="picbox">\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                            </div>\r\n                            <div class="text">\r\n                                <div class="time">2023.08.08</div>\r\n                                <div class="piece">36 pieces in total</div>\r\n                            </div>\r\n                        </a>\r\n                    </li>\r\n                    <li>\r\n                        <a href="javascript:;">\r\n                            <div class="picbox">\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                            </div>\r\n                            <div class="text">\r\n                                <div class="time">2023.08.08</div>\r\n                                <div class="piece">36 pieces in total</div>\r\n                            </div>\r\n                        </a>\r\n                    </li>\r\n                    <li>\r\n                        <a href="javascript:;">\r\n                            <div class="picbox">\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                            </div>\r\n                            <div class="text">\r\n                                <div class="time">2023.08.08</div>\r\n                                <div class="piece">36 pieces in total</div>\r\n                            </div>\r\n                        </a>\r\n                    </li>\r\n                    <li>\r\n                        <a href="javascript:;">\r\n                            <div class="picbox">\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                            </div>\r\n                            <div class="text">\r\n                                <div class="time">2023.08.08</div>\r\n                                <div class="piece">36 pieces in total</div>\r\n                            </div>\r\n                        </a>\r\n                    </li>\r\n                    <li>\r\n                        <a href="javascript:;">\r\n                            <div class="picbox">\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                                <div class="pic">\r\n                                    <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                                </div>\r\n                            </div>\r\n                            <div class="text">\r\n                                <div class="time">2023.08.08</div>\r\n                                <div class="piece">36 pieces in total</div>\r\n                            </div>\r\n                        </a>\r\n                    </li>\r\n                </ul>\r\n            </div>\r\n        </div>\r\n        <div class="show2">\r\n            <div class="list">\r\n                <ul>\r\n                    <li>\r\n                        <div class="pic">\r\n                            <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                        </div>\r\n                        <div class="text">\r\n                            <div class="out">\r\n                                 <img src="' +
((__t = ( require('./images/pro-out.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                            <h2><{$val.pc_name}></h2>\r\n                            <span><{$val.name}></span>\r\n                        </div>\r\n                    </li>\r\n                    <li>\r\n                        <div class="pic">\r\n                            <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                        </div>\r\n                        <div class="text">\r\n                            <div class="out">\r\n                                 <img src="' +
((__t = ( require('./images/pro-out.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                            <h2><{$val.pc_name}></h2>\r\n                            <span><{$val.name}></span>\r\n                        </div>\r\n                    </li>\r\n                    <li>\r\n                        <div class="pic">\r\n                            <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                        </div>\r\n                        <div class="text">\r\n                            <div class="out">\r\n                                 <img src="' +
((__t = ( require('./images/pro-out.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                            <h2><{$val.pc_name}></h2>\r\n                            <span><{$val.name}></span>\r\n                        </div>\r\n                    </li>\r\n                    <li>\r\n                        <div class="pic">\r\n                            <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                        </div>\r\n                        <div class="text">\r\n                            <div class="out">\r\n                                <img src="' +
((__t = ( require('./images/pro-out.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                            <h2><{$val.pc_name}></h2>\r\n                            <span><{$val.name}></span>\r\n                        </div>\r\n                    </li>\r\n                    <li>\r\n                        <div class="pic">\r\n                            <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                        </div>\r\n                        <div class="text">\r\n                            <div class="out">\r\n                                 <img src="' +
((__t = ( require('./images/pro-out.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                            <h2><{$val.pc_name}></h2>\r\n                            <span><{$val.name}></span>\r\n                        </div>\r\n                    </li>\r\n                    <li>\r\n                        <div class="pic">\r\n                            <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                        </div>\r\n                        <div class="text">\r\n                            <div class="out">\r\n                                 <img src="' +
((__t = ( require('./images/pro-out.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                            <h2><{$val.pc_name}></h2>\r\n                            <span><{$val.name}></span>\r\n                        </div>\r\n                    </li>\r\n                    <li>\r\n                        <div class="pic">\r\n                            <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                        </div>\r\n                        <div class="text">\r\n                            <div class="out">\r\n                                 <img src="' +
((__t = ( require('./images/pro-out.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                            <h2><{$val.pc_name}></h2>\r\n                            <span><{$val.name}></span>\r\n                        </div>\r\n                    </li>\r\n                    <li>\r\n                        <div class="pic">\r\n                            <img src="' +
((__t = ( require('./images/promodel1.png') )) == null ? '' : __t) +
'" alt="">\r\n                        </div>\r\n                        <div class="text">\r\n                            <div class="out">\r\n                                <img src="' +
((__t = ( require('./images/pro-out.png') )) == null ? '' : __t) +
'" alt="">\r\n                            </div>\r\n                            <h2><{$val.pc_name}></h2>\r\n                            <span><{$val.name}></span>\r\n                        </div>\r\n                    </li>\r\n                </ul>\r\n            </div>\r\n            <div class="bottom">\r\n                <div class="btn">\r\n                    <a href="javascript:;">\r\n                        Purchase Again\r\n                    </a>\r\n                    <a href="javascript:;" class="back">\r\n                        Return to List\r\n                    </a>\r\n                </div>\r\n                <div class="right">\r\n                    <div class="time">\r\n                        2023.08.08\r\n                    </div>\r\n                    <p>36 pieces in total</p>\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>';

}
return __p
}