import './index.scss'
$(".model-out").click(function () {
    $(".pro-model").hide();
})
$(".shop").click(function () {
    $(".pro-model").show();
})
$(".shop span").text($(".content .top ul li").length)
// $(".top").on("click",'.out',function(){
//     let listid = $(this).parent().parent().attr("id")
//     $(this).parent().parent().remove();
//     console.log(listid);
//     $(".shop span").text($(".content .top ul li").length)
// })
// $('.button .add').click(function () {
//     let title = $(".selection .active h2").text();
//     let text = $(".selection .active span").text();
//     let id1 = $(".selection .active").attr("id")
//     let selected = $(".pro-model .top ul")
//     let li = ''
//     li=document.createElement('li');
//     li.id = id1;
//     li.innerHTML='<div class="pic">' + $(".selection .active")[0].innerHTML +'</div>' + '<div class="text"><div class="out"><img src="./image/pro-out.png" alt=""></div><h2>'+ title + '</h2><span>' + text + '</span></div>'
//     selected.append(li);
//     $(".shop span").text($(".content .top ul li").length)
// })
// $(".show1 li").click(function() {
//     $(".show2").show()
//     $(".show1").hide()
// })
// $(".show2 .back").click(function () {
//     $(".show1").show()
//     $(".show2").hide()
// })