import './index.scss'

$(".hamburger").click(function(){
    $(this).toggleClass("active");
    $(".mob-nav").toggleClass("active");
    $("body").toggleClass("active");
})
$(".mob-nav .nLi").click(function() {
    $(this).find(".sub").slideToggle();
    $(this).toggleClass("active");
   
})
$(".search").click(function() {
    $(".search-xl").slideToggle();
})
$(".shopping").click(function() {
    $(".pro-model").show()
})