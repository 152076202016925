import './index.scss'
import '../../assets/scripts/myFoldpanel.js'
$(function() {
    $('dl#my-foldpanel').foldpanel({
        init:false,     	 //是否开启初始化功能,默认关闭
        init_index: 0, // 传的数字代表初始化展开的索引，0就是第一个
        time: 400, // panel展开动画时间, 默认为 100ms
        dbclose: true // 在此点击关闭, 默认为 true
    });
})
$(".foldpanel dt").click(function() {
    $(this).addClass("active").siblings().removeClass("active");
})
$(".foldpanel a").click(function() {
    $(this).parent().find("ul").slideToggle()
    $(this).toggleClass("on");
})